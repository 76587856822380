/** _my.scss */

/** 
 * My DoC module styles
 */

#mydoc_id_search {
	width: 100%;

	label {
		margin-right: 4px;
	}
}

#mydoc_id_search_field {
	display: inline-block;
	margin-top: 8px;
	margin-right: 4px;
	width: 106px;
	text-align: center;
	text-transform: lowercase;
}

#mydoc_id_search_go {
	/*display: block;
	margin: 8px auto 0;*/
}

#mydoc_map_status {
	z-index: 9999;
	position: absolute;
	left: 0;
	top: 0;
	padding: 8px;
	width: 100%;
	background: $frosted_glass_bg;
	color: $neutral_font_color;

	.spinner {
		margin-right: 4px;
	}
}

#mydoc_map {
    position: relative;
	margin: 8px 0;
	width: 100%;
	height: 300px;
	background: radial-gradient(circle, transparent 20%, rgb(234, 234, 234) 20%, rgb(234, 234, 234) 80%, transparent 80%, transparent), 
		radial-gradient(circle, transparent 20%, rgb(234, 234, 234) 20%, rgb(234, 234, 234) 80%, transparent 80%, transparent) 50px 50px,
		linear-gradient(silver 8px, transparent 8px) 0 -4px,
		linear-gradient(90deg, silver 8px, transparent 8px) -4px 0;
	background-color: rgb(234, 234, 234);
	background-size:100px 100px, 100px 100px, 50px 50px, 50px 50px;

	.alert {
		margin: 8px;
	}
}

#mydoc_details {
	padding: 8px 0;

	header {
		a {
			float: right;
			font-size: 0.9rem;
		}
	}

	dt {
		width: 35%;
	}

	#mydoc_details_videos_container {
		/** 
		 * position: relative; sets up the parent element for automatically scaling children
		 * Credit: http://www.mademyday.de/css-height-equals-width-with-pure-css.html
		 */
		position: relative;
		margin-bottom: 8px;
		width: 100%;

		/** This creates the structure/dimension via padding-top to achieve the desired ratio */
		&:before {
			content: '';
			display: block;
			padding-top: 56.25%;
		}

		/** This creates the structure/dimension via padding-top to achieve the desired ratio */
		.video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	#mydoc_details_photos_container {
		clear: both;

		#mydoc_details_photos_carousel {
			visibility: hidden;
		}
	}
}

@media (min-width: 992px) {
	.mydoc {
		/** Remove post-launch and activate in _html */
		h1, h2 {
			display: inline-block;
		}
		h1 {
			margin: 0 8px 0 0;
		}
	}

	#mydoc_id_search_field {
		//
	}

	#mydoc_id_search_go {
		display: inline-block;
		margin-right: 0;
		margin-left: 0;
	}

	#mydoc_map {
		float: left;
		width: 70%;
		height: 680px;
	}

	#mydoc_details {
		float: left;
		margin-left: 1%;
		width: 29%;

		dt {
			width: 32%;
		}
	}

	#mydoc_details_search_pointTo {
		display: none;
	}
}
