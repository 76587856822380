/** _mapbox.scss */
/** 
 * Mapbox overrides
 */

.mapboxgl-popup-content {
	padding: 12px 12px 8px;

	.mydoc_id {
		font-weight: bold;
	}
}

.mapboxgl-popup-close-button {
	height: 16px;
	line-height: normal;
}
