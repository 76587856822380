/** _typography.scss */
/** 
 * Typography
 */

@import url(https://fonts.googleapis.com/css?family=Gaegu:300,400,700|B612:400,400i,700,700i);

/*@font-face {
    font-family: 'Gaegu';
    src: url('https://fonts.googleapis.com/css?family=Gaegu:300,400,700');
}

@font-face {
    font-family: 'B612';
    src: url('https://fonts.googleapis.com/css?family=B612:400,400i,700,700i');
}*/
