/** _prelaunch.scss */
/** 
 * Prelaunch styles
 */

body.prelaunch {
	padding: 32px 0 0;
	// background-image: url(/assets/photos/james_creek_aspens_1.jpg);
	// background-size: cover;

	// background-image: url(/assets/photos/v_lg_sm_sm_1.jpg);
	// background-size: 150%;
	// background-position: 25% 20%;

	background-image: url(/assets/photos/v_sm_2.jpg);
	background-size: 170%;
	background-position: 50% 33%;

	background-repeat: no-repeat;

	@media (min-width: 992px) {
		// background-image: url(/assets/photos/rmnp_pond_and_peaks_1.jpg);
		
		// background-image: url(/assets/photos/h_sm_lg_1.jpg);
		// background-size: cover;
		// background-position: 50% 65%;

		background-image: url(/assets/photos/h_sm_2.jpg);
		background-size: cover;
		background-position: 50%;
	}

	text-align: center;

	header {
		padding: 16px 0;
		border-bottom: 1px solid rgb(255, 255, 255);
		background: $frosted_glass_bg;
	}

	h1 {
		margin: 0 auto;
		padding: 0;
	}

	h2 {
		margin: -4px 0 0;
	}

	.site-bars {
		display: none;
	}
}
