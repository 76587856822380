/** _site.scss */
/** 
 * Sitewide styles
 */

/*.site-bars {
	z-index: 9;
	position: fixed;
	width: 100vw;
	opacity: 0.8;
}

#skybar {
	top: 0;
	height: 24px;
	background: $highlight_color;
}

#earthbar {
	bottom: 0;
	height: 8px;
	background: $secondary_color;
}*/

#site_header {
	padding-right: 5vw;
	padding-left: 5vw;
	/*background-color: $primary_color_bg;
	border-bottom: 1px solid $primary_color;*/
	background-color: $highlight_color_bg;
	border-bottom: 1px solid $highlight_color;
}

video {
	z-index: -1;
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	// opacity: 0;
	background-size: cover;

	transition: opacity .5s linear;
}

@media (min-width: 992px) {
	#site_header {
		padding-right: 10vw;
		padding-left: 10vw;
	}
}
